import { injectable } from "inversify"

import * as Api from "../constants/ApiUrlConstants"
import { LoginModel } from "../model/LoginModel"
import container from "../wires/Bootstrapper"
import SERVICE_IDENTIFIER from "../wires/Identifiers"
import { IAuthService } from "./security/AuthServiceTs"

import { IApiService } from "./shared/ApiService"
import { ITraceService } from "./TraceService"
import { ModelBase } from "../model/BaseModels"

export interface IAuthenticationService {
    Login(UserName: string, Password: string): Promise<any>
    Verifica2fa(UserName: string, Password: string, Code: string): Promise<any>
    Request2FA(UserName: string): Promise<ModelBase> 
    RequestPassword(UserName: string): Promise<any>
    Logout(token: string): Promise<any>
}

@injectable()
export class AuthenticationService implements IAuthenticationService {

    apiService: IApiService
    authService: IAuthService
    traceService: ITraceService

    constructor() {
        this.apiService = container.get(SERVICE_IDENTIFIER.API_SERVICE)
        this.authService = container.get(SERVICE_IDENTIFIER.AUTH_SERVICE)
        this.traceService = container.get(SERVICE_IDENTIFIER.TRACE_SERVICE)
    }

    async Login(UserName: string, Password: string): Promise<any> {
        var resp = await this.apiService.postAsync<any>(Api.CheckPassword, { UserName, Password })

        return resp
    }


    async Request2FA(UserName: string): Promise<ModelBase> {
        var resp = await this.apiService.postAsync<ModelBase>(Api.Request2FAUrl, { UserName })
        return resp
    }

    async Verifica2fa(UserName: string, Password: string, Code: string): Promise<any> {
        var resp = await this.apiService.postAsync<any>(Api.Verifica2FAUrl, { mail: UserName, password: Password , code:Code})
        if (resp.IsValid == true) {
            //mostra componente 2fa

            let model = new LoginModel()
            model.UserName = UserName
            model.Token = resp.AuthToken
            model.Refreshtoken = resp.RefreshToken
            this.authService.signIn(model, () => { })

            await this.traceService.TraceAccess()

            this.authService.restart();
        }

        return resp
    }

    async RequestPassword(UserName: string): Promise<any> {
        return await this.apiService.postAsync<any>(Api.RequestPassword, { UserName })
    }

    async Logout(token: string): Promise<any> {
        return await this.apiService.getAsync<any>(Api.Logout,"")
    }
}